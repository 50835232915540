import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { TERMS_CONDITONS } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const TermsConditionCard = () => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(TERMS_CONDITONS)}
          title="Terms & Conditions"
          description="Company Terms and Conditions"
          buttonMessage="Terms & Conditions"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faBalanceScale} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={faBalanceScale}/>
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

export default TermsConditionCard;
